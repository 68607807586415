import React, { useEffect, useState } from "react";
import "./App.css";

function App() {
  const [entries, setEntries] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchEntries();
  }, []);

  const fetchEntries = async () => {
    const corsProxy = "https://api.allorigins.win/get?url=";
    const apiUrl = encodeURIComponent(
      "https://events.revnitro.com/api/events/6707cbbbf61ccb6a3b6a5ad6"
    );

    try {
      const response = await fetch(`${corsProxy}${apiUrl}`);
      const data = await response.json();
      const eventData = JSON.parse(data.contents);
      setEntries(eventData.entries);
    } catch (err) {
      console.error("Error fetching data:", err);
      setError("Failed to fetch data");
    }
  };

  return (
    <div className="App">
      <h1>Event Entries</h1>
      {error && <p>{error}</p>}
      <div className="entries-container">
        {entries.map((entry) => (
          <div key={entry._id} className="card">
            <h3>{entry.entryName}</h3>
            <p>
              <strong>Email:</strong> {entry.email}
            </p>
            <p>
              <strong>Mobile:</strong> {entry.mobileNumber}
            </p>
            <p>
              <strong>Occupation:</strong> {entry.occupation}
            </p>
            <p>
              <strong>Address:</strong> {entry.address}
            </p>
            <p>
              <strong>Location:</strong> {entry.location}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
